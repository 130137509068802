@font-face {
font-family: '__hoves_98ad28';
src: url(/_next/static/media/f9847f5e881dfda8-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__hoves_98ad28';
src: url(/_next/static/media/2c0adb158b40ea6b-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hoves_98ad28';
src: url(/_next/static/media/4247b587fbc3f14c-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__hoves_Fallback_98ad28';src: local("Arial");ascent-override: 94.46%;descent-override: 21.10%;line-gap-override: 2.81%;size-adjust: 99.51%
}.__className_98ad28 {font-family: '__hoves_98ad28', '__hoves_Fallback_98ad28'
}.__variable_98ad28 {--font-hoves: '__hoves_98ad28', '__hoves_Fallback_98ad28'
}

